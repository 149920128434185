import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { CSSProperties, FunctionComponent } from 'react'
import type { QuoteType } from '../../generated/contember/zeus'
import type { ImageResult } from '../data/ImageFragment'
import { GREY_BORDER_COLOR } from './ArticlePromoBox'
import { Icon } from './Icon'
import styles from './Quote.module.sass'

interface RichTextNode {
	text?: string
	content?: RichTextNode[]
	children?: RichTextNode[]
	[key: string]: unknown
}

const extractTextFromRichText = (jsonContent: string): string => {
	try {
		const content = JSON.parse(jsonContent) as RichTextNode | RichTextNode[]
		const texts: string[] = []
		const traverse = (node: RichTextNode | RichTextNode[] | string): void => {
			if (typeof node === 'string') {
				texts.push(node)
			} else if (Array.isArray(node)) {
				node.forEach(traverse)
			} else if (node && typeof node === 'object') {
				if (node.text) texts.push(node.text)
				if (node.content) traverse(node.content)
				if (node.children) traverse(node.children)
			}
		}
		traverse(content)
		return texts.join(' ')
	} catch (e) {
		return ''
	}
}

type QuoteProps = {
	color?: string
	image?: ImageResult
	type?: QuoteType
	quoteText?: string
	author?: string
	shareable?: boolean
	role?: string
	jsonContent: string
}

export const Quote: FunctionComponent<QuoteProps> = ({ color, image, type, author, jsonContent, role, shareable }) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				type === 'box' && styles.wrapperBox,
				// type === 'primary' && styles.wrapperPrimary,
				// type === 'secondary' && styles.wrapperSecondary,
			)}
			style={
				{
					'--quote-color': color ?? GREY_BORDER_COLOR,
				} as CSSProperties // Custom properties not supported workaround
			}
		>
			<div className={styles.in}>
				<div className={styles.text}>
					<RichTextRenderer source={jsonContent} />
				</div>
				{author && (
					<div className={styles.meta}>
						{image && (
							<div className={styles.imageWrapper}>
								<Image src={image.url} alt={image.alt ?? ''} fill />
							</div>
						)}
						<div className={styles.metaContent}>
							<div className={styles.author}>{author}</div>
							<div className={styles.role}>{role}</div>
						</div>
					</div>
				)}

				{shareable && (
					<div className={styles.shareButtons}>
						<button
							type="button"
							className={styles.iconButton}
							onClick={() => {
								window.open(
									`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
									'_blank',
									'noopener,noreferrer',
								)
							}}
						>
							<div className={styles.iconBg}>
								<div className={styles.iconWrapper}>
									<Icon name="facebook" />
								</div>
							</div>
						</button>
						<button
							type="button"
							className={styles.iconButton}
							onClick={() => {
								window.open(
									`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`,
									'_blank',
									'noopener,noreferrer',
								)
							}}
						>
							<div className={styles.iconBg}>
								<div className={styles.iconWrapper}>
									<Icon name="linkedin" />
								</div>
							</div>
						</button>
						<button
							type="button"
							className={styles.iconButton}
							onClick={() => {
								window.open(
									`https://twitter.com/intent/tweet?text=${encodeURIComponent(extractTextFromRichText(jsonContent))}`,
									'_blank',
									'noopener,noreferrer',
								)
							}}
						>
							<div className={styles.iconBg}>
								<div className={styles.iconWrapper}>
									<Icon name="x" />
								</div>
							</div>
						</button>
					</div>
				)}
			</div>
		</div>
	)
}
