import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import { createContext, useContext, useEffect, useState } from 'react'
import { ArticlePage } from '../components/ArticlePage'
import { ArticlesPage } from '../components/ArticlesPage'
import { EventsPage } from '../components/EventsPage'
import { FilesPage } from '../components/FilesPage'
import { FrontPage } from '../components/FrontPage'
import { GenericPage } from '../components/GenericPage'
import { Layout } from '../components/Layout'
import { MagazineCategoryPage } from '../components/MagazineCategoryPage'
import { PodcastPage } from '../components/PodcastPage'
import { PodcastPageWithCategory } from '../components/PodcastPageWithCategory'
import { ProductNewsPage } from '../components/ProductNewsPage'
import { ProductNewsPageWithCategory } from '../components/ProductNewsPageWithCategory'
import { ScrollLock } from '../components/ScrollLock'
import { Seo } from '../components/Seo'
import { TvPage } from '../components/TvPage'
import { TvPageWithCategory } from '../components/TvPageWithCategory'
import { WebinarPage } from '../components/WebinarPage'
import { WebinarsPage } from '../components/WebinarsPage'
import { WikiPage } from '../components/WikiPage'
import { WikiPageWithCategory } from '../components/WikiPageWithCategory'
import { SiteContextProvider } from '../components/contexts/SiteContextProvider'
import { TranslationsContextProvider } from '../components/contexts/TranslationsContextProvider'
import { ArticleFragment, ArticleTileFragment } from '../data/ArticleFragment'
import { ArticlesPageFragment } from '../data/ArticlesPageFragment'
import { BannerFragment } from '../data/BannerFragment'
import { CommonPageFragment } from '../data/CommonPageFragment'
import { EventFragment } from '../data/EventFragment'
import { EventsPageFragment } from '../data/EventsPageFragment'
import { FileFragment } from '../data/FileFragment'
import { FilesPageFragment } from '../data/FilesPageFragment'
import { FrontPageFragment } from '../data/FrontPageFragment'
import { GenericPageFragment } from '../data/GenericPageFragment'
import { InternalLinkFragment } from '../data/InternalLinkFragment'
import { LinkFragment } from '../data/LinkFragment'
import { MagazineCategoryFragment } from '../data/MagazineCategoryFragment'
import { PaginateFileFragment, getFilePagination } from '../data/PaginateFileFragment'
import { PaginateMagazineArticleFragment, getMagazineArticlePagination } from '../data/PaginateMagazineArticleFragment'
import { PaginateMagazineCategoryArticleFragment } from '../data/PaginateMagazineCategoryArticleFragment'
import { PaginatePodcastArticleFragment, getPodcastArticlePagination } from '../data/PaginatePodcastArticleFragment'
import {
	PaginateProductNewsArticleFragment,
	getProductNewsArticlePagination,
} from '../data/PaginateProductNewsArticleFragment'
import { PaginateWikiArticleFragment, getWikiArticlePagination } from '../data/PaginateWikiArticleFragment'
import { PodcastCategoryFragment, PodcastCategoryInfoFragment } from '../data/PodcastCategoryFragment'
import { PodcastPageFragment } from '../data/PodcastPageFragment'
import { ProductNewsCategoryFragment, ProductNewsCategoryInfoFragment } from '../data/ProductNewsCategoryFragment'
import { ProductNewsPageFragment } from '../data/ProductNewsPageFragment'
import { SearchPageFragment } from '../data/SearchPageFragment'
import { SiteFragment } from '../data/SiteFragment'
import { PaginateTvArticleFragment, getTvArticlePagination } from '../data/TvArticleFragment'
import { TvCategoryFragment, TvCategoryInfoFragment } from '../data/TvCategoryFragment'
import { TvPageFragment } from '../data/TvPageFragment'
import { WebinarCategoryFragment } from '../data/WebinarCategoryFragment'
import { WebinarDateFragment } from '../data/WebinarDateFragment'
import { WebinarFragment } from '../data/WebinarFragment'
import { WebinarLabelFragment } from '../data/WebinarLabelFragment'
import { WebinarsPageFragment } from '../data/WebinarsPageFragment'
import { WikiCategoryFragment, WikiCategoryInfoFragment } from '../data/WikiCategoryFragment'
import { WikiPageFragment } from '../data/WikiPageFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { getCodesAndUrlFromContext } from '../utilities/getCodesAndUrlFromContext'
import { getNonHiddenContentBlocks } from '../utilities/getNonHiddenContentBlocks'
import { getMonthFromDate, getYearAndMonthFromDate } from '../utilities/getYearAndMonthFromDate'
import { isDefined } from '../utilities/isDefined'
import { roundTheCurrentTimeToMinutes } from '../utilities/roundTheCurrentTimeToMinutes'
import { WEB_URL } from '../utilities/webUrl'

export type PageProps = InferGetStaticPropsType<typeof getStaticProps>

export const SECONDARY_FOOTER_URL = 'https://footer.almacareer.tech/v2/base/dark/latest'

export default function ({
	globalContext,
	baseUrl,
	url,
	siteCode,
	header,
	magazineHeader,
	page,
	footer,
	translations,
	secondaryFooter,
	pageDataLayer,
	seo,
}: PageProps) {
	const [firstLoadedPage] = useState(pageDataLayer)

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (firstLoadedPage !== pageDataLayer) {
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: 'page_virtual',
					page: {
						virtual: true,
						...pageDataLayer,
					},
					_clear: true,
				})
			}
		}, 100)

		return () => clearTimeout(timeout)
	}, [firstLoadedPage, pageDataLayer, url])

	return (
		<>
			{/* According to the assignment the dataLayer suppose to be rendered before GTM is rendered. This commented script below renders before GTM in the <Head>...</Head> but doesn't push any data to the dataLayer */}
			{/* <Script id="page-dataLayer" strategy="beforeInteractive">{`
				window.dataLayer = window.dataLayer || []

				window.dataLayer.push({
					event: 'page_virtual',
					page: {
						${
							pageDataLayer &&
							Object.entries(pageDataLayer)
								.map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
								.join(',\n')
						},
					}
					_clear: true
			})
			`}</Script> */}
			<Seo {...seo} />
			<GlobalContext.Provider value={globalContext}>
				<SiteContextProvider data={{ siteCode, baseUrl }}>
					<TranslationsContextProvider data={translations}>
						<ScrollLock>
							<Layout
								currentPageType={globalContext.currentPageType}
								header={header}
								magazineHeader={magazineHeader}
								footer={footer}
								secondaryFooter={secondaryFooter}
							>
								{page &&
									(page.frontPage ? (
										<FrontPage {...page.frontPage} />
									) : page.magazineCategoryPage ? (
										<MagazineCategoryPage {...page.magazineCategoryPage} />
									) : page.wikiPage ? (
										<WikiPage {...page.wikiPage} />
									) : page.wikiPageWithCategory ? (
										<WikiPageWithCategory {...page.wikiPageWithCategory} />
									) : page.articlesPage ? (
										<ArticlesPage {...page.articlesPage} />
									) : page.article ? (
										<ArticlePage {...page.article} />
									) : page.eventsPage ? (
										<EventsPage {...page.eventsPage} />
									) : page.eventsArchivePage ? (
										<EventsPage {...page.eventsArchivePage} isArchive />
									) : page.filesPage ? (
										<FilesPage {...page.filesPage} />
									) : page.tvPageWithCategory ? (
										<TvPageWithCategory {...page.tvPageWithCategory} />
									) : page.tvPage ? (
										<TvPage {...page.tvPage} />
									) : page.webinarsPage ? (
										<WebinarsPage {...page.webinarsPage} />
									) : page.webinar ? (
										<WebinarPage {...page.webinar} />
									) : page.productNewsPage ? (
										<ProductNewsPage {...page.productNewsPage} />
									) : page.productNewsPageWithCategory ? (
										<ProductNewsPageWithCategory {...page.productNewsPageWithCategory} />
									) : page.podcastPage ? (
										<PodcastPage {...page.podcastPage} />
									) : page.podcastPageWithCategory ? (
										<PodcastPageWithCategory {...page.podcastPageWithCategory} />
									) : page.genericPage ? (
										<GenericPage {...page.genericPage} />
									) : null)}
							</Layout>
						</ScrollLock>
					</TranslationsContextProvider>
				</SiteContextProvider>
			</GlobalContext.Provider>
		</>
	)
}

export const getStaticPaths = (async () => {
	const { listLinkable } = await contember.query({
		listLinkable: [
			{
				filter: {
					and: [
						{
							redirect: { id: { isNull: true } },
						},
						{
							article: { id: { isNull: true } },
						},
						{
							magazineCategory: { id: { isNull: true } },
						},
						{
							podcastCategory: { id: { isNull: true } },
						},
						{
							productNewsCategory: { id: { isNull: true } },
						},
						{
							tvCategory: { id: { isNull: true } },
						},
						{
							wikiCategory: { id: { isNull: true } },
						},
						{
							genericPage: { id: { isNull: true } },
						},
					],
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')
			return {
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
}) satisfies GetStaticPaths

export const getStaticProps = (async (context) => {
	const { baseUrl, url, siteCode, siteTitle } = await getCodesAndUrlFromContext(context)
	// const isGlobalSite = siteCode === 'global'

	const clearUrl = url.split('/preview=')[0]
	const data = await contember.query({
		...CommonPageFragment(siteCode),
		getLinkable: [
			{
				by: {
					url: clearUrl,
					site: {
						code: siteCode,
					},
				},
			},
			{
				url: true,
				webinar: [{}, WebinarFragment()],
				podcastCategory: [{}, PodcastCategoryFragment()],
				tvCategory: [{}, TvCategoryFragment()],
				productNewsCategory: [{}, ProductNewsCategoryFragment()],
				wikiCategory: [{}, WikiCategoryFragment()],
				frontPage: [{}, FrontPageFragment()],
				article: [{}, ArticleFragment()],
				articlesPage: [{}, ArticlesPageFragment()],
				genericPage: [{}, GenericPageFragment()],
				tvPage: [{}, TvPageFragment()],
				podcastPage: [{}, PodcastPageFragment()],
				productNewsPage: [{}, ProductNewsPageFragment()],
				filesPage: [{}, FilesPageFragment()],
				searchPage: [{}, SearchPageFragment()],
				eventsPage: [{}, EventsPageFragment()],
				eventsArchivePage: [{}, EventsPageFragment()],
				wikiPage: [{}, WikiPageFragment()],
				webinarsPage: [{}, WebinarsPageFragment()],
				magazineCategory: [{}, MagazineCategoryFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
	})

	const socialLinks = data.getGeneral?.socialLinks ?? null
	const translations = data.getTranslationDomain

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const frontPage = await (async () => {
		const initial = data.getLinkable?.frontPage ?? null

		if (!initial) {
			return null
		}

		const {
			magazineArticles,
			getArticlesPage,
			getTvPage,
			getEventsPage,
			getFilesPage,
			getPodcastPage,
			getProductNewsPage,
			getWikiPage,
			podcastArticles,
			tvArticles,
			productNewsArticles,
			recommendedBanners,
			frontPageLowerBanners,
			wikiArticles,
			listEvent,
		} = await contember.query({
			__alias: {
				magazineArticles: {
					listArticle: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ isPublished: { eq: true } },
									{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
									{
										not: {
											magazineCategories: {
												showOnMagazineCategoryPage: { eq: false },
											},
										},
									},
									{ magazineCategories: { id: { isNull: false } } },
								],
							},
							limit: 3,
							orderBy: [{ publishedAt: 'desc' }],
						},
						ArticleTileFragment(),
					],
				},
				recommendedBanners: {
					listBanner: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ bannerPosition: { eq: 'frontPageRecommended' } },
									{ showSinceDate: { lte: roundTheCurrentTimeToMinutes() } },
									{ showUntilDate: { or: [{ gt: roundTheCurrentTimeToMinutes() }, { isNull: true }] } },
									// { enabled: { eq: true } },
								],
							},
						},
						BannerFragment(),
					],
				},
				frontPageLowerBanners: {
					listBanner: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ bannerPosition: { eq: 'frontPageLower' } },
									{ showSinceDate: { lte: roundTheCurrentTimeToMinutes() } },
									{ showUntilDate: { or: [{ gt: roundTheCurrentTimeToMinutes() }, { isNull: true }] } },
									{ enabled: { eq: true } },
								],
							},
						},
						BannerFragment(),
					],
				},
				podcastArticles: {
					listArticle: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ isPublished: { eq: true } },
									{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
									{ podcastCategories: { id: { isNull: false } } },
								],
							},
							limit: 3,
							orderBy: [{ publishedAt: 'desc' }],
						},
						ArticleTileFragment(),
					],
				},
				tvArticles: {
					listArticle: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ isPublished: { eq: true } },
									{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
									{ tvCategories: { id: { isNull: false } } },
								],
							},
							limit: 1,
							orderBy: [{ publishedAt: 'desc' }],
						},
						ArticleTileFragment(),
					],
				},
				productNewsArticles: {
					listArticle: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ isPublished: { eq: true } },
									{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
									{ productNewsCategories: { id: { isNull: false } } },
								],
							},
							limit: 3,
							orderBy: [{ publishedAt: 'desc' }],
						},
						ArticleTileFragment(),
					],
				},
				wikiArticles: {
					listArticle: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ isPublished: { eq: true } },
									{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
									{ wikiCategories: { id: { isNull: false } } },
								],
							},
							limit: 3,
							orderBy: [{ publishedAt: 'desc' }],
						},
						ArticleTileFragment(),
					],
				},
			},
			listEvent: [
				{
					limit: 6,
					orderBy: [{ fromDate: 'asc' }],
					filter: {
						and: [{ site: { code: { eq: siteCode } } }, { fromDate: { gte: roundTheCurrentTimeToMinutes() } }],
					},
				},
				EventFragment(),
			],
			getArticlesPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getTvPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getProductNewsPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getPodcastPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getEventsPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getEventsArchivePage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getWikiPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
			getFilesPage: [{ by: { site: { code: siteCode } } }, { link: [{}, InternalLinkFragment()] }],
		})

		const { listFile } = await contember.query({
			listFile: [
				{
					limit: listEvent.length > 0 ? 4 : 6,
					orderBy: [{ uploadDate: 'desc' }],
					filter: {
						and: [
							{ site: { code: { eq: siteCode } } },
							{ uploadDate: { lte: roundTheCurrentTimeToMinutes() } },
							{ hiddenInListing: { eq: false } },
						],
					},
				},
				FileFragment(),
			],
		})

		const listEventsWithFormattedDate = listEvent.map((event) => ({
			...event,
			eventMonth: getMonthFromDate(event.fromDate, siteCode),
		}))

		return {
			...initial,
			magazineArticles,
			podcastArticles,
			frontPageLowerBanners: frontPageLowerBanners,
			recommendedBanners: recommendedBanners,
			tvArticles,
			articlesPageUrl: getArticlesPage?.link?.url ?? null,
			productNewsPageUrl: getProductNewsPage?.link?.url ?? null,
			tvPageUrl: getTvPage?.link?.url ?? null,
			wikiPageUrl: getWikiPage?.link?.url ?? null,
			eventsPageUrl: getEventsPage?.link?.url ?? null,
			filesPageUrl: getFilesPage?.link?.url ?? null,
			podcastPageUrl: getPodcastPage?.link?.url ?? null,
			productNewsArticles,
			wikiArticles,
			events: listEventsWithFormattedDate,
			files: listFile,
		}
	})()

	const webinar = await (async () => {
		const initial = data.getLinkable?.webinar ?? null

		if (!initial) {
			return null
		}

		return {
			...initial,
		}
	})()

	const article = await (async () => {
		const initial = data.getLinkable?.article ?? null

		const { params } = context
		const paramsArr = params?.path as string[]
		const isPreview = paramsArr?.includes(`preview=${initial?.id}`)

		if (
			!initial ||
			(!isPreview && !initial.isPublished) ||
			(!isPreview && initial.isPublished && initial.publishedAt && initial.publishedAt > roundTheCurrentTimeToMinutes())
		) {
			return null
		}

		const { getPodcastPage, getTvPage, getProductNewsPage, getWikiPage, getArticlesPage, listBanner } =
			await contember.query({
				getPodcastPage: [
					{
						by: {
							site: {
								code: siteCode,
							},
						},
					},
					{ link: [{}, { url: true }] },
				],
				getTvPage: [
					{
						by: {
							site: {
								code: siteCode,
							},
						},
					},
					{ link: [{}, { url: true }] },
				],
				listBanner: [
					{
						limit: 1,
						orderBy: [{ _random: true }],
						filter: {
							and: [
								{
									site: {
										code: {
											eq: siteCode,
										},
									},
								},
								{ bannerPosition: { eq: 'articleSidebar' } },
								{ showSinceDate: { lte: roundTheCurrentTimeToMinutes() } },
								{ showUntilDate: { or: [{ gt: roundTheCurrentTimeToMinutes() }, { isNull: true }] } },
								{ enabled: { eq: true } },
							],
						},
					},
					BannerFragment(),
				],
				getProductNewsPage: [
					{
						by: {
							site: {
								code: siteCode,
							},
						},
					},
					{ link: [{}, { url: true }] },
				],
				getWikiPage: [
					{
						by: {
							site: {
								code: siteCode,
							},
						},
					},
					{ link: [{}, { url: true }] },
				],
				getArticlesPage: [
					{
						by: {
							site: {
								code: siteCode,
							},
						},
					},
					{ link: [{}, { url: true }] },
				],
			})

		return {
			...initial,
			banner: listBanner[0] ?? null,
			content: getNonHiddenContentBlocks(initial.content),
			podcastPageLink: getPodcastPage?.link ?? null,
			tvPageLink: getTvPage?.link ?? null,
			productNewsPageLink: getProductNewsPage?.link ?? null,
			wikiPageLink: getWikiPage?.link ?? null,
			articlesPageLink: getArticlesPage?.link ?? null,
		}
	})()

	const wikiPage = await (async () => {
		const initial = data.getLinkable?.wikiPage ?? null

		if (!initial) {
			return null
		}

		// const res = await contember.query(PaginateWikiArticleFragment(siteCode, 1))

		const menuCategories = await contember.query({
			listWikiCategory: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ public: { eq: true } },
						],
					},
				},
				WikiCategoryInfoFragment(),
			],
		})

		// const wikiArticlePagination = getWikiArticlePagination(1, res)

		const wikiArticles = await contember.query({
			listArticle: [
				{
					orderBy: [{ publishedAt: 'desc' }],
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
							{ isPublished: { eq: true } },
							{ wikiCategories: { id: { isNull: false } } },
						],
					},
				},
				ArticleTileFragment(),
			],
		})

		return {
			...initial,
			wikiArticles: wikiArticles.listArticle,
			// wikiArticlePagination,
			menuCategories: menuCategories.listWikiCategory,
		}
	})()

	const wikiPageWithCategory = await (async () => {
		const initial = data.getLinkable?.wikiCategory ?? null

		if (!initial) {
			return null
		}

		const { wikiCategory, wikiCategories, getWikiPage } = await contember.query({
			__alias: {
				wikiCategories: {
					listWikiCategory: [
						{
							filter: {
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
						},
						WikiCategoryInfoFragment(),
					],
				},
				wikiCategory: {
					listWikiCategory: [
						{
							filter: {
								link: { url: { eq: initial.link?.url } },
							},
						},
						WikiCategoryInfoFragment(),
					],
				},
			},
			getWikiPage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},
				{
					heading: true,
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const res = await contember.query(PaginateWikiArticleFragment(siteCode, 1, wikiCategory[0]?.link?.url))

		const wikiArticlePagination = getWikiArticlePagination(1, res)

		return {
			...initial,
			page: getWikiPage,
			wikiArticlePagination,
			category: wikiCategory[0] ?? null,
			menuCategories: wikiCategories,
		}
	})()

	const magazineCategoryPage = await (async () => {
		const initial = data.getLinkable?.magazineCategory ?? null

		if (!initial || !initial.public) {
			return null
		}

		const res = await contember.query(PaginateMagazineCategoryArticleFragment(siteCode, 1, initial.link?.url))

		const magazineCategoryArticlePagination = getMagazineArticlePagination(1, res)

		return {
			...initial,
			magazineCategoryArticlePagination,
		}
	})()

	const podcastPage = await (async () => {
		const initial = data.getLinkable?.podcastPage ?? null

		if (!initial) {
			return null
		}

		const res = await contember.query(PaginatePodcastArticleFragment(siteCode, 1))

		const menuCategories = await contember.query({
			listPodcastCategory: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ public: { eq: true } },
							{
								articles: {
									and: [{ isPublished: { eq: true } }, { publishedAt: { lte: roundTheCurrentTimeToMinutes() } }],
								},
							},
						],
					},
				},
				PodcastCategoryInfoFragment(),
			],
		})

		const podcastArticlePagination = getPodcastArticlePagination(1, res)

		return {
			...initial,
			podcastArticlePagination,
			menuCategories: menuCategories.listPodcastCategory,
		}
	})()

	const podcastPageWithCategory = await (async () => {
		const initial = data.getLinkable?.podcastCategory ?? null

		if (!initial) {
			return null
		}

		const { podcastCategory, podcastCategories, getPodcastPage } = await contember.query({
			__alias: {
				podcastCategories: {
					listPodcastCategory: [
						{
							filter: {
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
						},
						PodcastCategoryInfoFragment(),
					],
				},
				podcastCategory: {
					listPodcastCategory: [
						{
							filter: {
								link: { url: { eq: initial.link?.url } },
							},
						},
						PodcastCategoryInfoFragment(),
					],
				},
			},
			getPodcastPage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},

				{
					heading: true,
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const res = await contember.query(PaginatePodcastArticleFragment(siteCode, 1, podcastCategory[0]?.link?.url))

		const podcastArticlePagination = getPodcastArticlePagination(1, res)

		return {
			...initial,
			heading: initial.title,
			page: getPodcastPage,
			podcastArticlePagination,
			category: podcastCategory[0] ?? null,
			menuCategories: podcastCategories,
		}
	})()

	const tvPage = await (async () => {
		const initial = data.getLinkable?.tvPage ?? null

		if (!initial) {
			return null
		}

		const res = await contember.query(PaginatePodcastArticleFragment(siteCode, 1))

		const menuCategories = await contember.query({
			listTvCategory: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ public: { eq: true } },
						],
					},
				},
				TvCategoryInfoFragment(),
			],
		})

		const tvArticlePagination = getTvArticlePagination(1, res)

		return {
			...initial,
			tvArticlePagination,
			menuCategories: menuCategories.listTvCategory,
		}
	})()

	const tvPageWithCategory = await (async () => {
		const initial = data.getLinkable?.tvCategory ?? null

		if (!initial) {
			return null
		}

		const { tvCategory, tvCategories, getTvPage } = await contember.query({
			__alias: {
				tvCategories: {
					listTvCategory: [
						{
							filter: {
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
						},
						TvCategoryInfoFragment(),
					],
				},
				tvCategory: {
					listTvCategory: [
						{
							filter: {
								link: { url: { eq: initial.link?.url } },
							},
						},
						TvCategoryInfoFragment(),
					],
				},
			},
			getTvPage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},

				{
					heading: true,
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const res = await contember.query(PaginateTvArticleFragment(siteCode, 1, tvCategory[0]?.link?.url))

		const tvArticlePagination = getTvArticlePagination(1, res)

		return {
			...initial,
			page: getTvPage,
			tvArticlePagination,
			category: tvCategory[0] ?? null,
			menuCategories: tvCategories,
		}
	})()

	const articlesPage = await (async () => {
		const initial = data.getLinkable?.articlesPage ?? null

		if (!initial) {
			return null
		}

		const res = await contember.query(PaginateMagazineArticleFragment(siteCode, 1))

		const magazineArticlePagination = getMagazineArticlePagination(1, res)

		return {
			...initial,
			magazineArticlePagination,
		}
	})()

	const productNewsPage = await (async () => {
		const initial = data.getLinkable?.productNewsPage ?? null

		if (!initial) {
			return null
		}

		// const res = await contember.query(PaginateProductNewsArticleFragment(siteCode, 1))

		const menuCategories = await contember.query({
			listProductNewsCategory: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ public: { eq: true } },
						],
					},
					orderBy: [{ title: 'desc' }],
				},
				ProductNewsCategoryInfoFragment(),
			],
		})

		const productNewsArticles = await contember.query({
			listArticle: [
				{
					orderBy: [{ publishedAt: 'desc' }],
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ publishedAt: { lte: roundTheCurrentTimeToMinutes() } },
							{ isPublished: { eq: true } },
							{ productNewsCategories: { id: { isNull: false } } },
						],
					},
				},
				ArticleTileFragment(),
			],
		})

		// const productNewsArticlePagination = getProductNewsArticlePagination(1, res)

		return {
			...initial,
			// productNewsArticlePagination,
			productNewsArticles: productNewsArticles.listArticle,
			menuCategories: menuCategories.listProductNewsCategory,
		}
	})()

	const productNewsPageWithCategory = await (async () => {
		const initial = data.getLinkable?.productNewsCategory ?? null

		if (!initial) {
			return null
		}

		const { productNewsCategories, productNewsCategory, getProductNewsPage } = await contember.query({
			__alias: {
				productNewsCategories: {
					listProductNewsCategory: [
						{
							filter: {
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							orderBy: [{ title: 'desc' }],
						},
						ProductNewsCategoryInfoFragment(),
					],
				},
				productNewsCategory: {
					listProductNewsCategory: [
						{
							filter: {
								link: { url: { eq: initial.link?.url } },
							},
						},
						ProductNewsCategoryInfoFragment(),
					],
				},
			},
			getProductNewsPage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},
				{
					heading: true,
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const res = await contember.query(
			PaginateProductNewsArticleFragment(siteCode, 1, productNewsCategory[0]?.link?.url),
		)

		const productNewsArticlePagination = getProductNewsArticlePagination(1, res)

		return {
			...initial,
			page: getProductNewsPage,
			productNewsArticlePagination,
			category: productNewsCategory[0] ?? null,
			menuCategories: productNewsCategories,
		}
	})()

	const webinarsPage = await (async () => {
		const initial = data.getLinkable?.webinarsPage ?? null

		if (!initial) {
			return null
		}

		const webinars = await contember.query({
			__alias: {
				pastWebinarDates: {
					listWebinarDate: [
						{
							orderBy: [
								{
									date: 'desc',
								},
							],
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},

									{ date: { lte: roundTheCurrentTimeToMinutes() } },
								],
							},
						},
						WebinarDateFragment(),
					],
				},
				openWebinarDates: {
					listWebinarDate: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ date: { gt: roundTheCurrentTimeToMinutes() } },
									{ isOpenForRegistration: { eq: true } },
								],
							},
						},
						WebinarDateFragment(),
					],
				},
				futureWebinarDates: {
					listWebinarDate: [
						{
							filter: {
								and: [
									{
										site: {
											code: {
												eq: siteCode,
											},
										},
									},
									{ date: { gt: roundTheCurrentTimeToMinutes() } },
									{ isOpenForRegistration: { eq: false } },
								],
							},
						},
						WebinarDateFragment(),
					],
				},
			},
			listWebinarLabel: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
							{ webinars: { id: { notEq: null } } },
						],
					},
				},
				WebinarLabelFragment(),
			],
			listWebinarCategory: [
				{
					filter: {
						and: [
							{
								site: {
									code: {
										eq: siteCode,
									},
								},
							},
						],
					},
				},
				WebinarCategoryFragment(),
			],
		})

		return {
			...initial,
			pastWebinarDates: webinars.pastWebinarDates,
			openWebinarDates: webinars.openWebinarDates,
			futureWebinarDates: webinars.futureWebinarDates,
			listWebinarLabel: webinars.listWebinarLabel,
			listWebinarCategory: webinars.listWebinarCategory,
		}
	})()

	const eventsPage = await (async () => {
		const initial = data.getLinkable?.eventsPage ?? null

		if (!initial) {
			return null
		}

		const { listEvent, getEventsArchivePage } = await contember.query({
			listEvent: [
				{
					filter: {
						site: {
							code: {
								eq: siteCode,
							},
						},
						fromDate: { gte: roundTheCurrentTimeToMinutes() },
					},
					orderBy: [{ fromDate: 'asc' }],
				},
				EventFragment(),
			],
			getEventsArchivePage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},
				{
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const listEventsWithFormattedDate = listEvent.map((event) => ({
			...event,
			eventYearAndMonth: getYearAndMonthFromDate(event.fromDate, siteCode),
			eventMonth: getMonthFromDate(event.fromDate, siteCode),
		}))

		const getMonthAndYear = (date: string) => date.split('T')[0].substring(0, 7)

		const dateCode = (code) => {
			if (code === 'cz') {
				return 'cs'
			}
			if (code === 'pl') {
				return 'pl'
			}
			return 'en'
		}

		const dateCodeBySite = dateCode(siteCode)

		const renameDates = (date: string) => {
			const yearNumber = getMonthAndYear(date).split('-')[0]
			const monthNumber = getMonthAndYear(date).split('-')[1]

			const monthName = Intl.DateTimeFormat(dateCodeBySite, {
				month: 'long',
			}).format(new Date(monthNumber))

			return `${monthName} ${yearNumber}`
		}

		const formattedYearAndMonths = listEvent.map((event) => renameDates(event.fromDate))

		const removeDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) === index)

		const uniqueMonths = removeDuplicates(formattedYearAndMonths)

		return {
			...initial,
			listEvent: listEventsWithFormattedDate,
			listMonths: uniqueMonths,
			buttonUrl: getEventsArchivePage?.link?.url ?? null,
			siteCode,
		}
	})()

	const eventsArchivePage = await (async () => {
		const initial = data.getLinkable?.eventsArchivePage ?? null

		if (!initial) {
			return null
		}

		const { listEvent, getEventsPage } = await contember.query({
			listEvent: [
				{
					filter: {
						site: {
							code: {
								eq: siteCode,
							},
						},
						fromDate: { lt: roundTheCurrentTimeToMinutes() },
					},
					orderBy: [{ fromDate: 'desc' }],
				},
				EventFragment(),
			],
			getEventsPage: [
				{
					by: {
						site: {
							code: siteCode,
						},
					},
				},
				{
					link: [{}, InternalLinkFragment()],
				},
			],
		})

		const listEventsWithFormattedDate = listEvent.map((event) => ({
			...event,
			eventYearAndMonth: getYearAndMonthFromDate(event.fromDate, siteCode),
			eventMonth: getMonthFromDate(event.fromDate, siteCode),
		}))

		const getMonthAndYear = (date: string) => date.split('T')[0].substring(0, 7)

		const dateCode = (code) => {
			if (code === 'cz') {
				return 'cs'
			}
			if (code === 'pl') {
				return 'pl'
			}
			return 'en'
		}

		const dateCodeBySite = dateCode(siteCode)

		const renameDates = (date: string) => {
			const yearNumber = getMonthAndYear(date).split('-')[0]
			const monthNumber = getMonthAndYear(date).split('-')[1]

			const monthName = Intl.DateTimeFormat(dateCodeBySite, {
				month: 'long',
			}).format(new Date(monthNumber))

			return `${monthName} ${yearNumber}`
		}

		const formattedYearAndMonths = listEvent.map((event) => renameDates(event.fromDate))

		const removeDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) === index)

		const uniqueMonths = removeDuplicates(formattedYearAndMonths)

		return {
			...initial,
			listEvent: listEventsWithFormattedDate,
			listMonths: uniqueMonths,
			buttonUrl: getEventsPage?.link?.url ?? null,
			siteCode,
		}
	})()

	const searchPage = await (async () => {
		const initial = data.getLinkable?.searchPage ?? null

		if (!initial) {
			return null
		}

		return {
			...initial,
		}
	})()

	// const errorPage = await (async () => {
	// 	const initial = data.getLinkable?.errorPage ?? null

	// 	if (!initial) {
	// 		return null
	// 	}

	// 	return {
	// 		...initial,
	// 	}
	// })()

	const filesPage = await (async () => {
		const initial = data.getLinkable?.filesPage ?? null

		if (!initial) {
			return null
		}

		const res = await contember.query(PaginateFileFragment(siteCode, 1))
		const filePagination = getFilePagination(1, res)

		return {
			...initial,
			filePagination,
		}
	})()

	const genericPage = await (async () => {
		const initial = data.getLinkable?.genericPage ?? null

		if (!initial) {
			return null
		}

		return initial
	})()

	const page = {
		genericPage,
		frontPage,
		webinar,
		article,
		tvPage,
		tvPageWithCategory,
		articlesPage,
		productNewsPage,
		magazineCategoryPage,
		eventsPage,
		webinarsPage,
		filesPage,
		searchPage,
		eventsArchivePage,
		// errorPage,
		wikiPageWithCategory,
		productNewsPageWithCategory,
		podcastPage,
		podcastPageWithCategory,
		wikiPage,
	}

	type PageTypes = keyof typeof page

	let currentPageType = null as PageTypes | null

	const currentPage = Object.entries(page).find((page) => {
		currentPageType = page[0] as PageTypes
		return page[1] !== null
	})?.[1]

	if (!currentPage) {
		return {
			notFound: true,
			revalidate: 30,
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return WEB_URL + url
	})()

	const footerBanner = 'footerBanner' in currentPage ? currentPage.footerBanner : null

	const header = await (async () => {
		const initial = data.getHeader ?? null

		if (!initial) {
			return null
		}

		const { listSite } = await contember.query({
			listSite: [
				{
					filter: {
						and: [
							{
								code: {
									notEq: siteCode,
								},
							},
							{ isHiddenInSwitcher: { eq: false } },
						],
					},
				},
				SiteFragment(),
			],
		})

		return {
			logo: initial.logo ?? null,
			links:
				initial?.links?.items
					.map(({ link, nestedLinks }) => {
						if (!link) {
							return null
						}
						return {
							...link,
							nestedLinks,
							isActive:
								contemberLinkToHref(link) === data.getLinkable?.url ||
								(nestedLinks?.items.find((item) => {
									if (!item.link) {
										return
									}
									return contemberLinkToHref(item.link) === data.getLinkable?.url
								}) ??
									false),
						}
					})
					.filter(isDefined) ?? [],
			contactPageLink: initial.contactPageLink ?? null,
			siteTitle,
			listSite,
		}
	})()

	const magazineHeader = await (async () => {
		const initial = data.getMagazineHeader ?? null

		if (!initial) {
			return null
		}

		return {
			links:
				initial?.links?.items
					.map(({ link }) => {
						if (!link) {
							return null
						}
						return {
							...link,
							isActive: contemberLinkToHref(link) === data.getLinkable?.url,
						}
					})
					.filter(isDefined) ?? [],
		}
	})()

	const footer = await (async () => {
		const initial = data.getFooter ?? null

		if (!initial) {
			return null
		}

		return {
			...initial,
			// @TODO: remove "initial.socialLinks" after the migration in the model
			socialLinksList: socialLinks ?? initial.socialLinks ?? null,
		}
	})()

	const secondaryFooter = await fetch(SECONDARY_FOOTER_URL).then((response) => {
		return response.text()
	})

	type PageDataLayer = { [key: string]: string | number | boolean | null }

	const pageDataLayer: PageDataLayer | null = (() => {
		if (currentPageType === 'article' && article) {
			const articleAllCategories = [
				...article.magazineCategories.flatMap((category) => category.title),
				...article.wikiCategories.flatMap((category) => category.title),
				...article.podcastCategories.flatMap((category) => category.title),
				...article.tvCategories.flatMap((category) => category.title),
				...article.productNewsCategories.flatMap((category) => category.title),
			].filter(isDefined)

			const articleSelectedCategories = [
				article.magazineCategories.length > 0 ? 'magazine' : undefined,
				article.wikiCategories.length > 0 ? 'wiki' : undefined,
				article.podcastCategories.length > 0 ? 'podcast' : undefined,
				article.tvCategories.length > 0 ? 'tv' : undefined,
				article.productNewsCategories.length > 0 ? 'productNews' : undefined,
			].filter(isDefined)

			const dateInISOWithoutTimeString =
				article.publishedAt && new Date(article.publishedAt).toISOString().split('T')[0]
			const lastUpdatedInISOWithoutTimeString = new Date(article.lastUpdated).toISOString().split('T')[0]

			return {
				title: article.title ?? '',
				category: articleSelectedCategories.map((category) => category).join(', '),
				type: 'article_detail',
				lang: siteCode === 'cz' ? 'cs' : siteCode,
				country: siteCode ?? '',
				hostname: process.env.NEXT_PUBLIC_WEB_URL?.replace('https://', '') ?? '',
				url: process.env.NEXT_PUBLIC_WEB_URL + url,
				// referrer: '', // This value is not available in getStaticProps not event in document.referrer on client side. So the only option is (probably) via middleware. So we ask first client (LMC) if they really needs this info.
				// virtual: '??', // @TODO: what? - is virtual page? Wait for client (LMC) response.
				'article.id': article.id,
				'article.name': article.title ?? '',
				'article.brand': article.author?.name ?? '',
				'article.category': articleSelectedCategories.map((category) => category).join(', '),
				'article.variant': article.isPublished && Boolean(article.publishedAt) ? 'zveřejněný' : 'příprava',
				'article.tags':
					articleAllCategories.length > 0 ? articleAllCategories.map((category) => category).join(', ') : '',
				'article.published': dateInISOWithoutTimeString ?? '',
				'article.updated': lastUpdatedInISOWithoutTimeString,
			}
		}

		const currentPageTypeToDataLayer: { [key in PageTypes]: string } = {
			frontPage: 'homepage',
			magazineCategoryPage: 'magazine_category',
			wikiPage: 'wiki_page',
			wikiPageWithCategory: 'wiki_with_category',
			articlesPage: 'articles',
			article: 'article_detail',
			eventsArchivePage: 'events_archive',
			eventsPage: 'events',
			filesPage: 'files',
			tvPageWithCategory: 'tv_with_category',
			tvPage: 'tv',
			webinar: 'webinar_detail',
			webinarsPage: 'webinars',
			productNewsPage: 'product_news',
			productNewsPageWithCategory: 'product_news_with_category',
			podcastPage: 'podcast',
			podcastPageWithCategory: 'podcast_with_category',
			genericPage: 'generic_page',
			searchPage: 'search',
		}

		const currentPageTypeObject = Object.entries(currentPageTypeToDataLayer).find(([key]) => key === currentPageType)
		const formattedCurrentPageType = currentPageTypeObject ? currentPageTypeObject[1] : ''

		const currentPageDataLayer = {
			title: currentPage.seo?.title ?? '',
			category: 'magazine',
			type: formattedCurrentPageType, // homepage | magazine_category | wiki_page | wiki_with_category | articles | article_detail | events | files | tv_with_category | tv | webinars | webinar_detail | product_news | product_news_with_category | podcast | podcast_with_category | generic_page
			lang: siteCode === 'cz' ? 'cs' : siteCode,
			country: siteCode ?? '',
			hostname: process.env.NEXT_PUBLIC_WEB_URL ?? '',
			url: process.env.NEXT_PUBLIC_WEB_URL + url,
			// referrer: '', // This value is not available in getStaticProps not event in document.referrer on client side. So the only option is (probably) via middleware. So we ask first client (LMC) if they really needs this info.
			// virtual: '??', // @TODO: what? - is virtual page? Wait for client (LMC) response.
		}

		return currentPageDataLayer
	})()

	return {
		props: {
			globalContext: {
				currentPageType,
				socialLinks,
			},
			siteCode,
			url,
			baseUrl,
			general: data.getGeneral,
			header,
			magazineHeader,
			footer,
			secondaryFooter,
			footerBanner,
			page,
			translations,
			pageDataLayer,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneral?.seo ?? {}),
					...Object.fromEntries(Object.entries(currentPage.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 30,
	}
}) satisfies GetStaticProps

type GlobalContextProps = PageProps['globalContext']

const GlobalContext = createContext<GlobalContextProps | null>(null)

export const useGlobalContext = () => {
	const context = useContext(GlobalContext)

	if (!context) {
		throw new Error()
	}

	return context
}
