import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ArticleTileResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import styles from './ArticleBanner.module.sass'
import { LinkChildren } from './LinkChildren'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ArticleBannerProps = {
	article: ArticleTileResult
	borderColor?: string
}

export const ArticleBanner: FunctionComponent<ArticleBannerProps> = ({ article, borderColor }) => {
	const translation = useTranslate()
	return (
		<LinkChildren type="internal" link={article.link}>
			<div className={styles.wrapper} style={{ borderColor: `1px solid ${borderColor}` }}>
				<div className={styles.imageWrapper}>
					{article.productOwner?.image ? (
						<Image
							className={styles.image}
							src={article.productOwner.image.url}
							fill
							alt={article.productOwner.image.alt ?? ''}
						/>
					) : article.tilePhoto ? (
						<Image
							className={styles.image}
							src={article.tilePhoto.url}
							fill
							alt={article.tilePhoto.alt ?? ''}
							sizes="(min-width: 30rem) 30vw, (min-width: 48rem) 10vw, 50vw)"
						/>
					) : (
						<Image className={styles.image} src="/images/image.png" fill alt="Placeholder image" />
					)}
				</div>
				<div className={styles.contentWrapper}>
					{article.title && <h3 className={styles.title}>{article.title}</h3>}
					<div className={styles.description}>
						{article.actualizedAt ? (
							<div className={styles.date}>{`${formatDate('cs', article.actualizedAt)} ${translation(
								'article.actualizedAt',
							)}`}</div>
						) : (
							article.publishedAt && <div className={styles.date}>{formatDate('cs', article.publishedAt)}</div>
						)}
						{article.readingTime && article.readingTime > 0 && (
							<div className={styles.date}>
								{article.readingTime} {translation(`${getReadingTime(article.readingTime)}`)}
							</div>
						)}
					</div>
				</div>
			</div>
		</LinkChildren>
	)
}
