import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { BannerResult } from '../data/BannerFragment'
import styles from './SideBanner.module.sass'

interface SideBannerProps {
	banner?: BannerResult | null
}

export const SideBanner: FunctionComponent<SideBannerProps> = (props) => {
	if (!props.banner) return null

	const BannerContent = () => (
		<>
			{props.banner?.narrowImage ? (
				<div className={clsx(styles.imageWrapper, styles.imageWrapperDesktopOnly)}>
					<Image
						className={styles.image}
						src={props.banner.narrowImage.url}
						fill
						alt={props.banner.narrowImage.alt ?? ''}
						sizes="(min-width: 30rem) 30vw, (min-width: 48rem) 10vw, 50vw"
						priority
						quality={75}
					/>
				</div>
			) : (
				<div className={clsx(styles.imageWrapper, styles.imageWrapperDesktopOnly)}>
					<Image className={styles.image} src="/images/image.png" fill alt="Placeholder image" />
				</div>
			)}

			{props.banner?.wideImage ? (
				<div className={clsx(styles.imageWrapper, styles.imageWrapperMobileOnly)}>
					<Image
						className={clsx(styles.image, styles.mobileOnly)}
						src={props.banner.wideImage?.url ?? props.banner.wideImage.url}
						fill
						alt={props.banner.wideImage?.alt ?? props.banner.wideImage.alt ?? ''}
						sizes="(min-width: 30rem) 30vw, (min-width: 48rem) 10vw, 50vw)"
						priority
						quality={75}
					/>
				</div>
			) : (
				<div className={clsx(styles.imageWrapper, styles.imageWrapperMobileOnly)}>
					<Image className={styles.image} src="/images/image.png" fill alt="Placeholder image" />
				</div>
			)}
		</>
	)

	return props.banner.url ? (
		<Link href={props.banner.url} target="_blank" className={styles.wrapper}>
			<BannerContent />
		</Link>
	) : (
		<BannerContent />
	)
}
