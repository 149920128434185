import type { LightboxSource } from '@mangoweb/fslightbox'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import Image from 'next/image'
import React, { useMemo, useRef, useState, type FunctionComponent } from 'react'
import type { AsyncReturnType } from 'type-fest'
import type { ImageResult } from '../data/ImageFragment'
import { useMediaQuery } from '../hooks/useMediaQuery'
import styles from './Gallery.module.sass'

const loadLightbox = () => import('@mangoweb/fslightbox').then((module) => module.lightbox)

type Lightbox = AsyncReturnType<typeof loadLightbox>

export type GalleryProps = {
	id?: string
	items: ImageResult[]
}

export const Gallery: FunctionComponent<GalleryProps> = ({ id, items }) => {
	const [lightbox, setLightbox] = React.useState<null | Lightbox>(null)
	const [currentSlide, setCurrentSlide] = useState(0)
	const carouselRef = useRef<HTMLDivElement>(null)
	const isMobile = useMediaQuery('(max-width: 768px)')

	React.useEffect(() => {
		loadLightbox().then((lightbox) => setLightbox(() => lightbox))
	}, [])

	const MAX_NUM_OF_IMAGES_DISPLAYED = 8
	const gallery = useMemo(() => {
		if (!items || items.length === 0) {
			return null
		}

		if (lightbox) {
			const sources: LightboxSource[] = items.map((item) => ({
				source: item.url,
				type: 'image',
				caption: item.alt,
			}))

			return lightbox(sources, {
				onSlideActivate: (current, previous) => {
					if (!previous) {
						window.dataLayer?.push({
							event: 'gallery_open',
							gallery: {
								type: 'commonGallery',
								content: { id: id, imageCount: items.length },
								position: current.slideIndex + 1,
							},
						})
					}

					let eventName = 'gallery_next'
					if (previous && (current.slideIndex + 1) % items.length === previous.slideIndex) {
						eventName = 'gallery_prev'
					}
					window.dataLayer?.push({
						event: eventName,
						gallery: { position: current.slideIndex + 1 },
					})
				},
			})
		}
	}, [id, items, lightbox])

	const handlePrevious = () => {
		setCurrentSlide((prev) => (prev === 0 ? items.length - 1 : prev - 1))
	}

	const handleNext = () => {
		setCurrentSlide((prev) => (prev === items.length - 1 ? 0 : prev + 1))
	}

	const mobileCarouselComponent = (
		<div className={styles.carouselWrapper}>
			<div className={styles.carouselContainer} ref={carouselRef}>
				<div className={styles.carouselTrack} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
					{items.map((image, index) => (
						<div key={index} className={styles.carouselSlide}>
							<div className={styles.carouselImageWrapper}>
								<Image
									src={image.url}
									alt={image.alt ?? ''}
									fill
									className={styles.carouselImage}
									onClick={() => gallery?.open(index)}
								/>
							</div>
							<span className={styles.carouselImageCaption}>{image.alt}</span>
						</div>
					))}
				</div>
			</div>

			{items.length > 1 && (
				<div className={styles.carouselControls}>
					<button className={styles.carouselButton} onClick={handlePrevious}>
						<ChevronLeftIcon className={styles.iconLeft} />
					</button>
					<div className={styles.carouselIndicators}>
						{items.map((_, index) => (
							<button
								key={index}
								className={clsx(styles.carouselIndicator, currentSlide === index && styles.carouselIndicatorActive)}
								onClick={() => setCurrentSlide(index)}
							/>
						))}
					</div>
					<button className={styles.carouselButton} onClick={handleNext}>
						<ChevronRightIcon className={styles.iconLeft} />
					</button>
				</div>
			)}
		</div>
	)

	const galleryComponent = (
		<div
			style={
				{
					['--Gallery-extra-images']: items && items.length - (MAX_NUM_OF_IMAGES_DISPLAYED - 1),
				} as React.CSSProperties
			}
			className={clsx(
				styles.wrapper,
				items && items.length > MAX_NUM_OF_IMAGES_DISPLAYED && styles.overEight,
				items && items.length > 4 && styles.overFour,
			)}
			data-track-list={JSON.stringify({
				gallery: {
					type: 'commonGallery',
					content: { id: id, imageCount: items && items.length },
				},
			})}
		>
			<div className={styles.inner}>
				{items &&
					items
						.map((image, index) => {
							return (
								<button
									key={index}
									className={styles.imageWrapper}
									onClick={() => {
										gallery?.open(index)
									}}
								>
									<div className={styles.image}>
										{image && (
											<Image
												className={styles.imageIn}
												src={image.url}
												alt={image.alt ?? ''}
												title={image.alt}
												fill
												sizes="(min-width: 30rem) 30vw, 100vw)"
											/>
										)}
									</div>
								</button>
							)
						})
						.slice(0, MAX_NUM_OF_IMAGES_DISPLAYED)}
			</div>
		</div>
	)

	if (items.length === 0) {
		return null
	}

	if (items.length === 1) {
		const firstImage = items[0]
		return (
			<>
				<div className={styles.imageWrapperStadingAlone} style={{}}>
					<Image
						src={firstImage?.url}
						alt={firstImage.alt ?? ''}
						fill
						className={styles.firstImage}
						style={{ objectFit: 'contain' }}
					/>
				</div>
				{firstImage.alt && <span className={styles.imageInfo}>{firstImage.alt}</span>}
			</>
		)
	}

	return isMobile ? mobileCarouselComponent : galleryComponent
}
