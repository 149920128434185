'use client'

import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { BannerResult } from '../data/BannerFragment'
import styles from './FrontPageLowerBanner.module.sass'

interface FrontPageLowerBannerProps {
	banners: BannerResult[]
}

const BannerImage = ({ image, className }: { image?: { url: string; alt?: string }; className?: string }) => {
	if (!image?.url) return null

	return <Image className={clsx(styles.image, className)} src={image.url} fill alt={image.alt ?? ''} />
}

const BannerImageSet = ({ banner }: { banner: BannerResult }) => (
	<>
		<div className={clsx(styles.imageWrapper, styles.imageWrapperDesktop)}>
			<BannerImage image={banner.narrowImage} />
		</div>
		<div className={clsx(styles.imageWrapper, styles.imageWrapperMobile)}>
			<BannerImage image={banner.wideImage} />
		</div>
	</>
)

export const FrontPageLowerBanner: FunctionComponent<FrontPageLowerBannerProps> = (props) => {
	const { banners } = props
	const [currentSlide, setCurrentSlide] = useState(0)
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

	useEffect(() => {
		if (banners.length > 1) {
			startResetInterval()
			return () => removeInterval()
		}
	}, [banners.length])

	if (banners.length === 0) return null

	// If single banner, render simple version
	if (banners.length === 1) {
		const banner = banners[0]
		return banner.url ? (
			<Link href={banner.url} target="_blank" className={clsx(styles.wrapper, styles.borderRadius)}>
				<div className={clsx(styles.imageWrapper, styles.borderRadius)}>
					<BannerImageSet banner={banner} />
				</div>
			</Link>
		) : (
			<div className={clsx(styles.wrapper, styles.borderRadius)}>
				<div className={clsx(styles.imageWrapper, styles.borderRadius)}>
					<BannerImageSet banner={banner} />
				</div>
			</div>
		)
	}

	// For multiple banners, implement carousel
	const removeInterval = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current)
		}
	}

	const startResetInterval = () => {
		removeInterval()
		intervalRef.current = setInterval(() => {
			setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length)
		}, 8000)
	}

	const handlePrevSlide = () => {
		startResetInterval()
		setCurrentSlide((prevSlide) => (prevSlide - 1 + banners.length) % banners.length)
	}

	const handleNextSlide = () => {
		startResetInterval()
		setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length)
	}

	return (
		<div className={styles.carouselWrapper}>
			<button type="button" onClick={handlePrevSlide} className={styles.prevButton} aria-label="Previous slide">
				<ChevronLeftIcon className={styles.iconLeft} />
			</button>
			<button type="button" onClick={handleNextSlide} className={styles.nextButton} aria-label="Next slide">
				<ChevronRightIcon className={styles.iconRight} />
			</button>
			<div className={styles.slidesContainer}>
				{banners.map((banner, index) => {
					const positionClass =
						index === currentSlide ? styles.active : index < currentSlide ? styles.prev : styles.next

					return (
						<div key={index} className={`${styles.slide} ${positionClass}`}>
							{banner.url ? (
								<Link href={banner.url} target="_blank" className={styles.wrapper}>
									<div className={styles.imageWrapper}>
										<BannerImage image={banner.wideImage} />
									</div>
								</Link>
							) : (
								<div className={styles.wrapper}>
									<div className={styles.imageWrapper}>
										<BannerImage image={banner.wideImage} />
									</div>
								</div>
							)}
						</div>
					)
				})}
			</div>
			<div className={styles.dots}>
				{banners.map((_, index) => (
					<button
						key={`dot_${index}`}
						type="button"
						onClick={() => {
							startResetInterval()
							setCurrentSlide(index)
						}}
						className={`${styles.dot} ${index === currentSlide ? styles.activeDot : ''}`}
					/>
				))}
			</div>
		</div>
	)
}
